import Navbar from "../components/layout/Navbar";
import {useState} from "react";

export default function Kontakt(){
    const loginURL = 'https://propension.dk:5204/Kontakt';
    let [fornavn, setFornavn] = useState("");
    let [efternavn, setEfternavn] = useState("");
    let [email, setEmail] = useState("");
    let [message, setMessage] = useState("");
    let [tlf_nr, settlf_nr] = useState("");


    async function sendDataSimple(){
        console.log("hi");
        try {
            const type = "simple";
            let data = JSON.stringify({
                    fornavn: fornavn,
                    efternavn: efternavn,
                    email: email,
                    message: message,
                    tlf_nr: tlf_nr
                }
            );
            const options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: data
            };
            const response = await fetch(loginURL, options);

            if (response.ok) {
                // Request was successful
                var ok = document.getElementById("msg_ok");
                setTimeout(() => {
                    ok.classList.add("hidden");

                }, 3000);
                ok.classList.remove("hidden");
                console.log('Response Data:', response);
            } else {
                var err = document.getElementById("msg_err");
                setTimeout(() => {
                    err.classList.add("hidden");

                }, 3000);
                err.classList.remove("hidden");
                console.error('Request failed with status:', response.status);
            }
        }
        catch (e) {

        }
        return null;
    }
    // @ts-ignore
    return (

        <>
                    <div className="fixed z-20">
                        <Navbar/>
                    </div>
                    <br/>
                    <div className="my-64 bg-white font-textbitter flex justify-center items-center w-screen h-screen bg-white lg:my-32">
                        <div className="container mx-auto my-4 px-4 lg:px-20">

                            <div
                                className="w-full p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
                                <div className="flex">
                                    <h1 className="text-5xl">Kontakt os</h1>
                                </div>
                                {/*
                                <div className="hero my-10 ">
                                    <div className="text-center hero-content flex-col lg:flex-row-reverse lg:text-left">
                                        <img alt="Peter Dalsgaard" src={require("../images/peterfront.jpg")}
                                             className="max-w-[15em] rounded-lg shadow-xl self-center"/>
                                        <div>
                                            <div className="mt-3 mx-auto text-xl self-center">
                                                <p className="text-gray-800 tracking-wide">Peter Dalsgaard</p>
                                                <p className=" text-gray-500 leading-7 ">peter@propension.dk</p>
                                                <p className=" text-gray-500 leading-7 ">(+45)
                                                    23905602</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                */}
                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                    <input
                                        className="w-full bg-gray-100 z-10 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="text" placeholder="Fornavn*" value={fornavn} onClick={console.log(fornavn)} onInput={e => setFornavn(e.target.value)}/>
                                    <input
                                        className="w-full bg-gray-100 z-10 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="text" placeholder="Efternavn*" value={efternavn} onClick={console.log(efternavn)} onInput={e => setEfternavn(e.target.value)}/>
                                    <input
                                        className="w-full bg-gray-100 z-10 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="email" placeholder="Email*" value={email} onClick={console.log(email)} onInput={e => setEmail(e.target.value)}/>
                                    <input
                                        className="w-full bg-gray-100 z-10 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="number" placeholder="Telefon-nummer*" value={tlf_nr} onClick={console.log(tlf_nr)} onInput={e => settlf_nr(e.target.value)}/>
                                </div>

                                <div className="my-4">
                                    <textarea placeholder="Din besked til os"
                                              className="w-full h-32 bg-gray-100 z-50 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline" value={message} onClick={console.log(message)} onInput={e => setMessage(e.target.value)}/>
                                </div>
                                <div className="my-2 w-1/2 lg:w-1/4">
                                    <button className="text-sm z-[100] bg-[#34842b] text-gray-100 p-3 rounded-lg w-full
                                            focus:outline-none focus:shadow-outline" onClick={() => sendDataSimple()}>
                                        Send
                                    </button>

                                </div>
                                <p id="msg_ok" className="text-xl hidden">Tak! Vi kontakter dig hurtigst muligt &#10003;</p>
                                <p id="msg_err" className="text-xl hidden">Der har desværre opstået en fejl, prøv igen senere &#88;</p>
                            </div>

                            <div
                                className="w-full lg:-mt-96 lg:w-2/6 px-8 py-12 ml-auto bg-[#34842b] rounded-3xl">
                                <label className="swap swap-flip">
                                <div className="flex flex-col text-white swap-on">
                                    <h1 className="font-bold uppercase text-4xl my-4">Drop in our office</h1>
                                    <p className="text-gray-100">

                                    </p>

                                    <div className="flex my-4 w-2/3 lg:w-1/2">
                                        <div className="flex flex-col">
                                            <i className="fas fa-map-marker-alt pt-2 pr-2"/>
                                        </div>
                                        <div className="flex flex-col">
                                            <h2 className="text-2xl hover:cursor-pointer">Vores adresse</h2>
                                            <p className="text-gray-100">Lundvej 48 8800 Viborg</p>
                                        </div>
                                    </div>

                                    <div className="flex my-4 w-2/3 lg:w-1/2">
                                        <div className="flex flex-col">
                                            <i className="fas fa-phone-alt pt-2 pr-2"/>
                                        </div>
                                        <div className="flex flex-col">
                                            <h2 className="text-2xl">Ring til os</h2>
                                            <p className="text-gray-100">Tel: +45 23 90 56 02</p>
                                        </div>
                                    </div>
                                </div>
                                    <div className="swap-off">
                                        <div className="lg:h-full">
                                            <iframe
                                                className="absolute w-full h-full"
                                                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=48%20Lundvej%20,%20Viborg,%20Danmark+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                                                allowFullScreen
                                                loading="lazy"
                                                />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>


                        <input type="checkbox" id="googleMapsModal" className="modal-toggle"/>
                        <div className="modal">
                            <div className="modal-box">
                                <div className="lg:w-1/2 w-full p-4">
                                    <div className="relative aspect-w-16 h-[50vw] lg:h-full aspect-h-9">
                                        <iframe
                                            className="absolute inset-0 w-full h-full"
                                            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=30A%20Sønderallé%20,%20Viborg,%20Danmark+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                                            allowFullScreen
                                            loading="lazy"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
</>
        );
    }