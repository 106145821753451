import {useInView} from "react-intersection-observer";
import Navbar from "../components/layout/Navbar";
// @ts-ignore
import logowhite from "../components/icons/logowhitegreen.svg";

import "../index.css";

export default function Home() {
    const {ref: navbarRef, inView: isVisibleNavbar} = useInView();
    const items = document.querySelectorAll('.hiddenTextBox')
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('show');
            }
        });
    });
    items.forEach((el) => observer.observe(el));

    return (
        <section id="Om" className="relative">
            <div className={`${isVisibleNavbar ? 'hidden' : 'visible'}`}>
                <Navbar/>
            </div>

            <div className="block lg:hidden w-screen flex justify-start absolute">
                <a href="/">
                    <img className="absolute scale-[120%] -top-8 text-white fill-current z-60" src={logowhite} alt="ProPension logo"/>
                </a>
            </div>

            <div className="w-screen absolute z-[100]">
                <nav className="container mx-auto flex items-center justify-around relative flex-wrap ">
                    <div>
                        <a href="/">
                            <img className="hidden lg:block scale-[200%] left-16 top-0 text-white fill-current" src={logowhite} alt="ProPension logo"/>
                        </a>
                    </div>
                    <div className="hidden lg:block text-xl text-white">
                        <a key="Forside" className="mx-3 py-3 hover:underline underline-offset-4" href="/">Om ProPension
                        </a>
                        <a key="Firmapension" className="mx-3 py-5 hover:underline underline-offset-4" href="/firmapension">Firmapension
                        </a>
                        <a key="Medarbejder" className="mx-3 py-5 hover:underline underline-offset-4" href="/medarbejder">Medarbejder
                        </a>
                        {/*<a key="Pensionsberegner" className="mx-3 py-5 hover:underline underline-offset-4"
                               href="/pensionsberegner">Pensionsberegner
                            </a>*/}
                        <a key="Sundhed" className="mx-3 py-5 hover:underline underline-offset-4" href="/sundhed">Sundhed
                        </a>
                        <a key="Kontakt" className="mx-3 py-5 hover:underline underline-offset-4" href="/kontakt">Kontakt
                        </a>

                        {/*<a key="Login" className="mx-3 py-5 hover:underline underline-offset-4" href="/login">Login
                        </a>*/}
                    </div>
                </nav>
            </div>


            <div ref={navbarRef} className="hero min-h-[29em] bg-image-meetingtopdown">
                <div className="hero-overlay bg-opacity-70"></div>
                <div className="hero-content text-center text-neutral-content">
                    <div className="max-w-md">
                        <h1 className="mb-2 my-[1.8em] text-5xl"><p className="font-bold">Firmapension</p></h1>
                        <div className="divider-vertical"/>
                        <button className="btn hidden btn-success mx-4 bg-[#34842b] text-gray-300 hover:bg-green-800 border-[#34842b] hover:border-green-800"><a href="/kontakt">Kontakt os</a></button>
                    </div>
                </div>
            </div>


            <section className="font-textbitter">
                {/*<div className="flex flex-col w-full">
                    <div className="divider"/>
                    <div className="grid h-20 card bg-base-300 place-items-center text-3xl font-textbitter">Om ProPension</div>
                    <div className="divider"/>
                </div>*/}

                <div className="hero my-10 ">
                    <div className="text-center hero-content flex-col lg:flex-row-reverse lg:text-left">
                        <img src={require("../images/AdobeStock_296543120_Preview.jpeg")}
                             className="max-w-[20em] rounded-lg shadow-xl lg:max-w-sm"/>
                        <div>
                            <h1 className=" text-3xl font-bold text-[#34842b]">Sikkerhed for den rigtige aftale</h1>
                            <p className="py-6 text-md ">ProPension er Din Pensionsrådgiver og sidder på samme side af bordet som dig. Vi er
                                ikke
                                bundet op på bestemte leverandører eller standardløsninger, men forhandler med alle
                                tilgængelige selskaber. Dette sikrer, at du og dine medarbejderer opnår de mest fordelagtige priser og vilkår på markedet.</p>
                        </div>
                    </div>
                </div>
                <div className="hero my-10 ">
                    <div className="text-center hero-content flex-col lg:flex-row lg:text-left">
                        <img src={require("../images/AdobeStock_474260258_Preview.jpeg")}
                             className="max-w-[20em] rounded-lg shadow-xl lg:max-w-sm"/>
                        <div>
                            <h1 className="text-3xl font-bold text-[#34842b]">En faglig stærk sparringspartner</h1>
                            <p className="py-6 text-md">Vi har et indgåede kendskab til pensionsselskabernes løsninger og tager udgangspunkt i den
                                enkelte medarbejders ønsker og behov.
                                Vi anvender avancerede analyseværktøjer, hvor
                                vi med afsæt i medarbejderens ønsker og økonomiske situation identificerer deres
                                individuelle behov og præsenterer de anbefalede løsninger klart og tydeligt.</p>
                        </div>
                    </div>
                </div>
                <div className="hero my-10 ">
                    <div className="text-center hero-content flex-col lg:flex-row-reverse lg:text-left">
                        <img src={require("../images/604.jpeg")}
                             className="max-w-[20em] rounded-lg shadow-xl lg:max-w-sm"/>
                        <div>
                            <h1 className=" text-3xl font-bold text-[#34842b]">Prioritering af virksomhedens drift</h1>
                            <p className="py-6 text-md ">Pensionsmarkedet er komplekst og det ændrer sig konstant. Som dine pålidelige rådgivere kan du
                                trygt overlade overvågningen og den løbende opdatering til os, mens du fokuserer på
                                virksomhedens drift. Det sparer virksomheden for tid og bøvl.</p>
                        </div>
                    </div>
                </div>
                <div className="hero my-10 ">
                    <div className="text-center hero-content flex-col lg:flex-row lg:text-left">
                        <img src={require("../images/peter_telefon4.jpeg")}
                             className="max-w-[20em] rounded-lg shadow-xl lg:max-w-sm"/>
                        <div>
                            <h1 className="text-3xl font-bold text-[#34842b]">En proaktiv rådgiver</h1>
                            <p className="py-6 text-md">Når en medarbejder rammes af alvorlig sygdom, der sker ændringer i regler og vilkår eller er behov for opdateringer, så kommer vi med klare råd og anbefalinger til dig, så du hurtigt og sikkert kan træffe beslutning om nye tiltag eller ændring af pensionsaftalen.
                            </p>
                        </div>
                    </div>
                </div>

            </section>



            <section className="p-12 font-textbitter">

                <div className="hero my-10">
                    <div className="text-center hero-content flex-col lg:flex-row lg:text-left max-w-2xl">
                        <div>
                            <div className="steps steps-vertical">

                                <div className="step step-neutral">
                                    <div tabIndex="0"
                                         className="">
                                        <div className="text-xl font-bold text-[#34842b]">Gennemgang
                                        </div>
                                        <div className="py-6 text-md">
                                            <p>Vi gennemgår jeres nuværende situation og pensionsløsning, herunder medarbejdernes udnyttelse af de individuelle tilpasningsmuligheder på forsikringsdækninger, opsparings- og investeringsvalg. Med afsæt i jeres krav og ønsker til pensionsordningen udarbejder vi forslag til nyt og opdateret pensionsdesign.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="step step-neutral">
                                    <div tabIndex="0"
                                         className="">
                                        <div className="text-xl font-bold text-[#34842b]">
                                            Analyse
                                        </div>
                                        <div className="py-6 text-md">
                                            <p>Med udgangspunkt i det nye pensionsdesign indhenter vi nye tilbud fra såvel jeres nuværende leverandør som øvrige relevante pensionsselskaber. Vi gennemgår de indkomne tilbud og udfærdiger en detaljeret analyse med klare beskrivelser af de enkelte løsningers fordele og ulemper.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="step step-neutral">
                                    <div tabIndex="0">
                                        <div className="text-xl font-bold text-[#34842b]">
                                            Anbefaling
                                        </div>
                                        <div className="py-6 text-md">
                                            <p>Vi præsenterer analysen og vores anbefalinger for dig, så du får det bedst mulige overblik over de mange parametre, der tilsammen skal sikre dig en stærk og fleksibel pensionsaftale. Vores mission er at finde den rigtige løsning til jer. Derfor kan anbefalingen sagtens pege på det nuværende selskab, men med en opdateret aftale og bedre udnyttelse af individuelle tilpasningsmuligheder.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="step step-neutral">
                                    <div tabIndex="0">
                                        <div className="text-xl font-bold text-[#34842b]">
                                            Beslutning
                                        </div>
                                        <div className="py-6 text-md">
                                            <p>Beslutningen om den fremtidige løsning er din! Men vi leverer analyse og anbefaling, så du kan træffe et oplyst valg, uden at fjerne fokus fra dine kerneopgaver. Det giver tryghed for dig og dine medarbejdere – og sparer dig for tid og besvær.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="step step-neutral">
                                    <div tabIndex="0">
                                        <div className="text-xl font-bold text-[#34842b]">
                                            Implementering
                                        </div>
                                        <div className="py-6 text-md">
                                            <p>Implementering og individuel rådgivning udgør en essentiel del af processen. En fleksibel pensionsordning giver mulighed for et bredt udvalg af investeringsmuligheder. For at sikre det optimale udbytte gennemfører vi individuel og uafhængig rådgivning. Vi tilpasser forsikringsdækning, opsparings- og investeringsprofil samt begunstigelse tilpasses den enkelte medarbejders ønsker og behov.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="step step-neutral">
                                    <div tabIndex="0">
                                        <div className="text-xl font-bold text-[#34842b]">
                                            Overvågning
                                        </div>
                                        <div className="py-6 text-md">
                                            <p>Vi overvåger løbende din pensionsaftale og informerer dig om udviklingen af nye produkter og ændringer i vilkår – både hos dit og de øvrige pensionsselskaber. Samtidig arbejder vi på at identificere nye og bedre løsninger til den enkelte medarbejder.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>





                    {/* BOKS 4 */}
                    {/*
                        <div className="border-2 rounded-2xl bg-[#CFD9D3]">

                            <div className="font-textbitter">
                                <p className="text-sm text-left text-gray-500">(Overskrift baggrund i jeres valgte grøn)</p>
                                <div className="flex flex-row justify-center gap-5">

                                    <h2 className="p-6 text-xl font-bold">
                                        En proaktiv rådgiver, der kender markedet og arbejder for dig
                                    </h2>
                                    <button onClick={() => setClicked4(!clicked4)}>
                                        <svg
                                            className={`${clicked4 ? "rotate-90 duration-100" : "-rotate-none duration-100 "} scale-125 w-8 h-8`}
                                            width="32px" height="32px" viewBox="3 2.6 18 18" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z"
                                                fill="#0F0F0F"/>
                                        </svg>
                                    </button>
                                </div>
                                <p className={`${clicked4 ? "block transition duration-300" : "hidden"} rounded-2xl text-lg bg-white`}>
                                    Når en medarbejder rammes af alvorlig sygdom, der sker ændringer i regler og vilkår eller er
                                    behov for opdateringer, så kommer vi med klare råd og anbefalinger til dig, så du hurtigt og
                                    sikkert kan træffe beslutning om nye tiltag eller ændring af pensionsaftalen.
                                </p>
                            </div>
                        </div>
                        */}


                {/*
                        <div className="border-2">
                            <div className="justify-center">
                                <div className="flex justify-center lg:justify-start">
                                    <img/>
                                </div>
                                <h2 className="text-lg text-black py-4 lg:pt-9 lg:pb-6 lg:text-xl lg:font-bold">
                                    4. trin
                                </h2>
                                <p className="text-neut ral-grayish-blue text-sm font-textbitter lg:text-base leading-5">
                                    Pensionsmarkedet er komplekst og det ændrer sig konstant. Med os som rådgiver kan du trygt
                                    overlade overvågningen og den løbende opdatering til os, mens du fokuserer på
                                    virksomhedens drift. Det sparer virksomheden for tid og bøvl.
                                </p>
                            </div>
                        </div>
                        */}
                {/* {motivationItems.map((item) => (
                            <div key={item.title} className="justify-center">
                                <div className="flex justify-center lg:justify-start">
                                    <img src={item.iconPath} alt="" />
                                </div>

                                <h2 className="text-lg text-primary-dark-blue py-4 lg:pt-9 lg:pb-6 lg:text-xl lg:font-bold">
                                    {item.title}
                                </h2>
                                <p className="text-neut ral-grayish-blue text-sm font-light lg:text-base leading-5">
                                    {item.subtitle}
                                </p>
                            </div>
                        ))} */}



            </section>
        </section>

    );

}