import {useState} from "react";
import sha256 from 'crypto-js/sha256';
import md5 from 'crypto-js/md5';
import axios from "axios";


export default function Login(){

    const MY_CA_BUNDLE = "";
    const loginURL = 'https://www.propension.dk:5204/Login/Auth';
    //const loginURL = 'https://localhost:5204/Login/Auth';
    let [username, setUsername] = useState("");
    let [password, setPassword] = useState("");


    async function PostLogin(e){
        e.preventDefault();
        try {

            const hashedPassword = md5(password).toString();
            const data = JSON.stringify({
                username: username,
                password: hashedPassword,
            });

            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: data,
            };

            //const response = await fetch(loginURL, options);

            axios.post(loginURL, data, {
                headers: {
                    Accept: "*/*",
                    "Content-Type": "application/json;charset=UTF-8",
                },
            })
                .then(({data}) => {
                    console.log(data);
                })

/*
            if (response.ok) {
                // Request was successful
                const responseData = await response.json();
                console.log('Response Data:', responseData);
            } else {
                // Request failed
                console.error('Request failed with status:', response.status);
            }*/

        } catch (error) {
            // Catch and log any unexpected errors
            console.error('An error occurred:', error);
        }
        return null;


    }


    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 ">
                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="space-y-6" action="#" method="POST">
                        <div>
                            <label htmlFor="brugernavn" className="block text-sm font-medium leading-6 text-gray-900">
                                Brugernavn
                            </label>
                            <div className="mt-2">
                                <input
                                    id="brugernavn"
                                    name="username"
                                    type="text"
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#34842b] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-800 sm:text-sm sm:leading-6"
                                    value={username} onInput={e => setUsername(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="">
                            <div className="flex items-center justify-between">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Adgangskode
                                </label>
                            </div>
                            <div className="mt-2">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    className="z-50 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#34842b] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-800 sm:text-sm sm:leading-6"
                                    value={password} onInput={e => setPassword(e.target.value)}
                                />
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-[#34842b] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={(e => PostLogin(e))}>
                                Login
                            </button>
                        </div>
                    </form>

                </div>
            </div>
            <div className="my-64"/>

        </>
    );


};