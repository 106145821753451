import {BrowserRouter, Route, Routes} from "react-router-dom";
import Navbar from "./components/layout/Navbar.tsx";
import Pensionsberegner from "./pages/Pensionsberegner.tsx";
import Firmapension from  "./pages/Firmapension";
import Kontakt from "./pages/Kontakt";
import Medarbejder from "./pages/Medarbejder";
import Home from "./pages/Home.tsx";
import Footer from "./components/layout/Footer";
import Privatlivspolitik from "./pages/Privatlivspolitik.tsx";
import Sundhed from "./pages/Sundhed.tsx";
import Login from "./pages/Login";

const App = () => {
    return (
        <BrowserRouter history={window.history} basename="/">
            <div className="relative overflow-hidden">
                <Routes>
                    <Route exact path="/" element={<Home/>}/>
                    <Route exact path="/firmapension" element={<Firmapension/>}/>
                    <Route exact path="/medarbejder" element={<Medarbejder/>}/>
                    <Route exact path="/kontakt" element={<Kontakt/>}/>
                    <Route exact path="/sundhed" element={<Sundhed/>}/>
                    <Route exact path="/privatlivspolitik" element={<Privatlivspolitik/>}/>
                    <Route exact path="/login" element={<Login/>}/>
                </Routes>
            </div>
            <Footer/>
        </BrowserRouter>
    );
}

export default App;
