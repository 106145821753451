import {Line} from "react-chartjs-2";

import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Legend,
    Tooltip,
} from "chart.js";

ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Legend,
    Tooltip
);

const data = [];
const data2 = [];
const data3 = [];
const lab = [];
let prev = 100;
let prev2 = 80;
let prev3 = 60;
for (let i = 0; i < 100; i++) {
    lab.push(i);
    prev += 5 - Math.random() * 10;
    data.push({x: i, y: prev});
    prev2 += 5 - Math.random() * 10;
    data2.push({x: i, y: prev2});
    prev3 += 5 - Math.random() * 10;
    data3.push({x: i, y: prev3});
}

const config = {
    type: 'line',
    labels: lab,
    datasets: [
        {
            label: 'Høj risiko',
            data: data.map((value) => value.y),
            borderColor: '#CC0000',
        },
        {
            label: 'Normal risiko',
            data: data2.map((value) => value.y),
            borderColor: '#00CC00',
            tension: 1,
        },
        {
            label: 'Lav risiko',
            data: data3.map((value) => value.y),
            borderColor: '#00AACC',
        }
    ]
};

function HighRiskGraph() {
    return <Line data={config}/>;

}

export default HighRiskGraph;