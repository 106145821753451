
import {useInView} from "react-intersection-observer";
import Navbar from "../components/layout/Navbar";
// @ts-ignore
import logowhite from "../components/icons/logowhitegreen.svg";

import "../index.css";

export default function Medarbejder() {
    const {ref: navbarRef, inView: isVisibleNavbar} = useInView();
    const items = document.querySelectorAll('.hiddenTextBox')
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('show');
            } else {
                entry.target.classList.remove('show');
                entry.target.classList.add('hiddenTextBox')
            }
        });
    });
    items.forEach((el) => observer.observe(el));

    // @ts-ignore
    return (
        <section id="Om" className="relative">
            <div className={`${isVisibleNavbar ? 'hidden' : 'visible'}`}>
                <Navbar/>
            </div>

            <div className="block lg:hidden w-screen flex justify-start absolute">
                <a href="/">
                    <img className="absolute scale-[120%] -top-8 text-white fill-current z-60" src={logowhite}
                         alt="ProPension logo"/>
                </a>
            </div>

            <div className="w-screen absolute z-[100]">
                <nav className="container mx-auto flex items-center justify-around relative flex-wrap ">
                    <div>
                        <a href="/">
                            <img className="hidden lg:block scale-[200%] left-16 top-0 text-white fill-current"
                                 src={logowhite} alt="ProPension logo"/>
                        </a>
                    </div>
                    <div className="hidden lg:block text-xl text-white">
                        <a key="Forside" className="mx-3 py-3 hover:underline underline-offset-4" href="/">Om ProPension
                        </a>
                        <a key="Firmapension" className="mx-3 py-5 hover:underline underline-offset-4"
                           href="/firmapension">Firmapension
                        </a>
                        <a key="Medarbejder" className="mx-3 py-5 hover:underline underline-offset-4"
                           href="/medarbejder">Medarbejder
                        </a>
                        {/*<a key="Pensionsberegner" className="mx-3 py-5 hover:underline underline-offset-4"
                               href="/pensionsberegner">Pensionsberegner
                            </a>*/}
                        <a key="Sundhed" className="mx-3 py-5 hover:underline underline-offset-4" href="/sundhed">Sundhed
                        </a>
                        <a key="Kontakt" className="mx-3 py-5 hover:underline underline-offset-4" href="/kontakt">Kontakt
                        </a>

                        {/*<a key="Login" className="mx-3 py-5 hover:underline underline-offset-4" href="/login">Login
                        </a>*/}
                    </div>

                </nav>
            </div>


            <div ref={navbarRef} className="hero min-h-[29em] bg-image-coworker">
                <div className="hero-overlay bg-opacity-70"></div>
                <div className="hero-content text-center text-neutral-content">
                    <div className="max-w-md">
                        <h1 className="mb-2 my-[1.8em] text-5xl"><p className="font-bold">Medarbejder</p></h1>
                        <div className="divider-vertical"/>

                        <button className="btn hidden btn-success mx-4 bg-[#34842b] text-gray-300 hover:bg-green-800 border-[#34842b] hover:border-green-800"><a href="/kontakt">Kontakt os</a></button>

                    </div>
                </div>
            </div>


            <section className="font-textbitter">
                {/*<div className="flex flex-col w-full">
                    <div className="divider"/>
                    <div className="grid h-20 card bg-base-300 place-items-center text-3xl font-textbitter">Om ProPension</div>
                    <div className="divider"/>
                </div>*/}

                <div className="hero my-10 ">
                    <div className="text-center hero-content flex-col lg:flex-row-reverse lg:text-left">
                        <img alt="Peter Dalsgaard" src={require("../images/Peter-Dalsgaard-14.jpg")}
                             className="max-w-[20em] rounded-lg shadow-xl lg:max-w-sm"/>
                        <div>
                            <h1 className=" text-3xl font-bold text-[#34842b]">Personlig og individuel rådgivning
                            </h1>
                            <p className="py-6 text-md ">Sammen med din arbejdsgiver har vi brugt tid og energi på at
                                sikre dig og dine kollegaer en attraktiv og fleksibel pensionsordning. For at du kan få
                                det fulde udbytte af den, skal den tilpasses dine individuelle ønsker og behov.
                                <br/><br/>Vi tilbyder dig individuel pensions- og forsikringsanalyse, hvor vi tilpasser
                                forsikringsdækninger, opsparing- og investeringsprofil, begunstigelse m.m. i forhold til
                                dine aktuelle ønsker og behov.

                                <span> Du kan læse mere om indholdet i vores pensions- og forsikringsanalyse <span className="italic text-blue-950 "><a href="#dropdown"> nederst på siden.</a></span> </span>

                                <br/><br/>Vi tager udgangspunkt i din aktuelle økonomiske- og familiemæssige
                                situation, gennemgår dine nuværende pensionsordninger og personforsikringer og kommer
                                med klare og konkrete anbefalinger på, hvordan du sikrer dig den bedste fremtidige
                                løsning.
                                <br/><br/>Du kan finde oplysninger om dine eksisterende pensionsordninger på <a className="text-blue-950 italic" target="_blank" href="https://www.pensionsinfo.dk">www.pensionsinfo.dk</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="hero my-10 ">
                    <div className="text-center hero-content flex-col lg:flex-row lg:text-center">

                    </div>
                </div>
                <div className="hero my-10 ">
                    <div className="text-center hero-content flex-col lg:flex-row lg:text-left">

                    </div>
                </div>

                <div className="hero my-10">
                    <div className="text-center hero-content flex-col lg:flex-row lg:text-left ">

                        <div className="mockup-phone border-[#34842b] max-w-[19em] shadow-2xl">
                            <div className="camera"/>
                            <div className="display">
                                <div className="artboard artboard-demo phone-1"><img
                                    src={require("../images/app_1.jpg")}/></div>
                            </div>
                        </div>
                        <div className="max-w-2xl text-center">
                            <h1 className="text-2xl font-bold text-[#34842b]">Overvågning</h1>
                            <p className="py-6 text-md">Vi bestræber os på at sikre dig de bedst mulige løsninger inden for din pensionsordning. Vi overvåger nøje og evaluerer løbende markedet for pensionsprodukter og din eksisterende aftale for at finde og præsentere dig for nye og mere fordelagtige muligheder. Vi er dedikerede til at give dig det bedste.
                            </p>
                                <h1 className="text-2xl  font-bold text-[#34842b]">Vi gør det enkelt for dig
                                </h1>
                                <p className="py-6 text-md">Vi er her for at hjælpe dig! Du kan altid nå os, hvis du har spørgsmål eller behøver rådgivning om investering, pensionsbidrag, forsikringsdækning, ansøgning om erstatning samt tilmelding eller afmelding af familiemedlemmer på sundhedsforsikringen.
                                </p>
                        </div>
                    </div>
                </div>
                <div className="hero my-10" id="dropdown">
                    <div>
                        <h1 className="text-2xl text-center font-bold text-[#34842b] p-5">Pensions- og
                            forsikringsanalyse</h1>
                        <div className="collapse collapse-arrow bg-base-200">
                            <input type="checkbox" checked={true}/>
                            <div className="collapse-title text-sm hidden">
                                Forsikrings- og pensionsanalysen behandler følgende emner
                            </div>
                            <div className="collapse-content block px-12 my-4">
                                <ul>
                                    <li>
                                        <div className="collapse collapse-arrow bg-base-200">
                                            <input type="checkbox"/>
                                            <p className="collapse-title capital text-xl">Forsikringsdækninger</p>
                                            <ul className="collapse-content">
                                                <li className="indent-line">Livsforsikring</li>
                                                <li className="indent-line">Tab af erhvervsevne
                                                </li>
                                                <li className="indent-line">Kritisk sygdom
                                                </li>
                                                <li className="indent-line">Sundhed
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="collapse collapse-arrow bg-base-200">
                                            <input type="checkbox"/>
                                            <span className="collapse-title font-bold text-xl">Arv og begunstigelse</span>
                                            <ul className="collapse-content">
                                                <li className="indent-line">Udbetaling af livsforsikring</li>
                                                <li className="indent-line">Udbetaling af pensionsopsparing ved dødsfald</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="collapse collapse-arrow bg-base-200">
                                            <input type="checkbox"/>
                                            <span className="collapse-title capital text-xl">Pensionsprognose</span>
                                            <ul className="collapse-content">
                                                <li className="indent-line">Pensionsopsparing og -bidrag
                                                </li>
                                                <li className="indent-line">Offentlige ydelser
                                                </li>
                                                <li className="indent-line">Frie midler, fast ejendom, virksomhed m.m.

                                                </li>
                                                <li className="indent-line">Udbetaling – fordeling på sum, rate og livrente

                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="collapse collapse-arrow bg-base-200">
                                            <input type="checkbox"/>
                                            <span className="collapse-title capital text-xl">Investering</span>
                                            <ul className="collapse-content">
                                                <li className="indent-line">Afkast & risiko

                                                </li>
                                                <li className="indent-line">Aktiv forvaltning eller indeksfonde

                                                </li>
                                                <li className="indent-line">Fast profil eller livscyklus


                                                </li>
                                                <li className="indent-line">Fokus på bæredygtighed m.m.


                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="collapse collapse-arrow bg-base-200">
                                            <input type="checkbox"/>
                                            <span className="collapse-title capital text-xl">Overførsel</span>
                                            <ul className="collapse-content">
                                                <li className="indent-line">Udbetalingsrettigheder</li>
                                                <li className="indent-line">Investeringsmuligheder</li>
                                                <li className="indent-line">Gebyrer og omkostninger</li>
                                                <li className="indent-line">Overblik</li>
                                            </ul>
                                        </div>
                                    </li>


                                    <li className="m-3 mx-4">
                                        <label htmlFor="my_modal_2"><span className=" font-bold text-xl">Dine eksisterende forsikringsdækninger
                                            </span>
                                        </label>

                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>


            </section>

        </section>

    );

}