import {useInView} from "react-intersection-observer";
import Navbar from "../components/layout/Navbar";
// @ts-ignore
import logowhite from "../components/icons/logowhitegreen.svg";
import "../index.css";

export default function Home() {
    const {ref: navbarRef, inView: isVisibleNavbar} = useInView();
    const items = document.querySelectorAll('.hiddenTextBox')
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('show');
            }
        });
    });
    items.forEach((el) => observer.observe(el));

    return (
        <section id="Om" className="relative">
            <div className={`${isVisibleNavbar ? 'hidden' : 'visible'}`}>
                <Navbar/>
            </div>

            <div className="block lg:hidden w-screen flex justify-start absolute">
                <a href="/">
                    <img className="absolute scale-[120%] -top-8 text-white fill-current z-60" src={logowhite}
                         alt="ProPension logo"/>
                </a>
            </div>

            <div className="w-screen absolute z-[100]">
                <nav className="container mx-auto flex items-center justify-around relative flex-wrap ">
                    <div>
                        <a href="/">
                            <img className="hidden lg:block scale-[200%] left-16 top-0 text-white fill-current"
                                 src={logowhite} alt="ProPension logo"/>
                        </a>
                    </div>
                    <div className="hidden lg:block text-xl text-white">
                        <a key="Forside" className="mx-3 py-3 hover:underline underline-offset-4" href="/">Om ProPension
                        </a>
                        <a key="Firmapension" className="mx-3 py-5 hover:underline underline-offset-4"
                           href="/firmapension">Firmapension
                        </a>
                        <a key="Medarbejder" className="mx-3 py-5 hover:underline underline-offset-4"
                           href="/medarbejder">Medarbejder
                        </a>
                        {/*<a key="Pensionsberegner" className="mx-3 py-5 hover:underline underline-offset-4"
                               href="/pensionsberegner">Pensionsberegner
                            </a>*/}
                        <a key="Sundhed" className="mx-3 py-5 hover:underline underline-offset-4 " href="/sundhed">Sundhed
                        </a>
                        <a key="Kontakt" className="mx-3 py-5 hover:underline underline-offset-4" href="/kontakt">Kontakt
                        </a>

                        {/*<a key="Login" className="mx-3 py-5 hover:underline underline-offset-4" href="/login">Login
                        </a>*/}
                    </div>

                </nav>
            </div>


            <div ref={navbarRef} className="hero bg-image-sundhed min-h-[29em]">
                <div className="hero-overlay bg-opacity-[80%] "/>
                <div className="hero-content text-center text-neutral-content">
                    <div className="max-w-md">
                        <h1 className="mb-2 my-[1.8em] text-6xl">Velkommen til <p className="font-bold">ProPension</p></h1>
                        <div className="divider-vertical"/>
                        <p className="mb-5 text-xl font-textbitter">Vi er vågne i dag, så du kan være tryg i morgen

                        </p>
                        <button className="my-4 hidden btn btn-success mx-4 bg-[#34842b] text-gray-300 hover:bg-green-800 border-[#34842b] hover:border-green-800"><a href="#læsmere">Læs mere</a></button>
                        <button className="my-4 hidden btn btn-success mx-4 bg-[#34842b] text-gray-300 hover:bg-green-800 border-[#34842b] hover:border-green-800"><a href="/kontakt">Kontakt os</a></button>
                    </div>
                </div>
            </div>


            <section className="font-textbitter">
                {/*<div className="flex flex-col w-full">
                    <div className="divider"/>
                    <div className="grid h-20 card bg-base-300 place-items-center text-3xl font-textbitter">Om ProPension</div>
                    <div className="divider"/>
                </div>*/}

                <div className="hero" id="læsmere">
                    <div className="text-center hero-content flex-col lg:flex-row-reverse lg:text-left max-w-screen">
                        <img src={require("../images/Peter_AJ.png")}
                             className="max-w-[20em] rounded-lg shadow-xl lg:max-w-sm"/>
                        <div>
                            <h1 className=" text-3xl font-bold text-[#34842b]">Om ProPension

                            </h1>
                            <p className="py-6 text-md "> ProPension rådgiver virksomheder og medarbejdere om
                                pensionsordninger og sundhedsløsninger for at sikre dem bedre nu og i fremtiden. Vi har
                                særligt fokus på mindre og mellemstore virksomheder.
                                <br/><br/> Vi yder ligeledes individuel og uafhængig rådgivning til
                                virksomhedsindehavere og private om deres pensionsforhold.
                                <br/><br/> ProPension er en uafhængig forsikringsformidler, også kendt som pensionsmægler, der ikke
                                er bundet til enkelte forsikringsselskaber. Vores mål er at rådgive vores kunder på tværs af hele markedet og præsenterer
                                dem for den bedst mulige løsning. Vi er med andre ord DIN pensionsrådgiver.
                                <br/><br/> ProPension er registeret som uafhængig forsikringsformidler hos
                                Finanstilsynet.</p>
                        </div>
                    </div>
                </div>

                {/*<div className="hero my-10 ">
                    <div className="text-center hero-content max-width flex-col lg:flex-row lg:text-left">
                        <img src={require("../images/meeting.jpg")}
                             className="max-w-sm rounded-lg shadow-xl"/>
                        <div>
                            <h1 className="text-4xl font-bold text-[#34842b]">Hvad kan du forvente af os</h1>
                            <p className="py-6 text-xl">• Du får en personlig og uafhængig rådgiver, som varetager dine interesser.<br/><br/>• Vi rådgiver altid med udgangspunkt i dine ønsker og behov.<br/><br/>•  Vi anvender moderne beregningsmodeller og leverer saglige analyser og anbefalinger, der giver overblik og tryghed.<br/><br/> • Vi skaber overblik og løbende holder dig ajour med dine muligheder for forbedringer.<br/><br/> • Du kan fokusere på det du er bedst til – det gør vi!</p>

                        </div>
                    </div>
                </div> */}
                <div className="hero my-10">
                    <div className="text-center hero-content flex-col lg:flex-row lg:text-left max-w-xl">
                        <div>
                            <h1 className="text-2xl text-center font-bold text-[#34842b]">Vi gør det enkelt for dig
                            </h1>

                            <ul className="checklist">
                                <li className="checklist step py-6 text-md">Du får en personlig og uafhængig rådgiver, som varetager dine interesser.

                                </li>
                                <li className="checklist step py-6 text-md">Vi rådgiver altid med udgangspunkt i dine ønsker og behov.

                                </li>
                                <li className="checklist step py-6 text-md">Vi anvender moderne beregningsmodeller og leverer saglige analyser og anbefalinger, der giver overblik og tryghed.

                                </li>
                                <li className="checklist step py-6 text-md">Vi skaber overblik og løbende holder dig opdateret med dine muligheder for forbedringer

                                </li>
                                <li className="checklist step py-6 text-md">Du kan fokusere på det, du er bedst til – det gør vi!
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>

                <div className="max-w-screen hero my-10 lg:mx-0">
                    <div className="text-center hero-content flex-col lg:flex-row-reverse lg:text-left">
                        <div>
                            <h1 className=" text-2xl font-bold text-[#34842b]">Gør det enkelt og prioriter de vigtige opgaver</h1>
                            <p className="py-6 text-md ">
                                Din pension er vigtig. Det skal sikrer dig et godt og trygt liv i 15-20 år eller mere.
                                Selv små ændringer kan have stor betydning på langt sigt. <br/><br/>
                                Har du ikke tid til eller lyst i at følge din pensionsordning tæt, fordi du
                                ønsker at fokusere på det, du er bedst til- så er ProPension det ideelle valg
                                for dig og din virksomhed.</p>
                        </div>
                    </div>
                </div>

            </section>

            <div className="flex flex-col w-screen">
                <div className="divider"/>
                <div className="grid h-1/10 card place-items-center text-md text-center font-textbitter m-4">Leder du efter en uafhængig pensionsrådgiver?
                    <br/><span>For at høre mere om, hvordan
                    vi kan hjælpe dig med den rette firmapensionsaftale, ring til os på <a
                        className="text-green-950" href="tel:+45 23 90 56 02"> &#9742; 23 90 56 02</a></span> Det er uforpligtende, og det kræver
                    kun den tid,
                    du bruger på en telefonsamtale eller et møde med os.
                </div>
                <div className="divider"/>
            </div>
        </section>

    );

    {/*
    svag grøn: CFD9D3
    start grøn i gradient: C5E0B4
    */
    }
}