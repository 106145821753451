import {useState} from 'react';
// @ts-ignore
import logo from '../icons/logo.svg';
// @ts-ignore
import hamburger from "../icons/hamburger.svg";
// @ts-ignore
import close from '../icons/close.svg';


export default function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <div className="fixed z-50 w-[100%] h-24 bg-white shadow-sm shadow-[#34842b]">
                <nav className="container flex justify-between items-center z-20 overflow-hidden 0">
                    <div>
                        <img className="scale-125 -my-6" src={logo} alt="ProPension logo"/>
                    </div>

                    <div className="hidden lg:block flex justify-between font-textbitter text-xl font-bold text-[#34842b] ">
                        <a key="Forside" className="mx-3 py-3 hover:gradient-border-bottom" href="/">Om ProPension
                        </a>
                        <a key="Firmapension" className="mx-3 py-5 hover:gradient-border-bottom" href="/Firmapension">Firmapension
                        </a>
                        <a key="Medarbejder" className="mx-3 py-5 hover:gradient-border-bottom" href="/Medarbejder">Medarbejder
                        </a>
                        {/*<a key="Pensionsberegner" className="mx-3 py-5 hover:underline underline-offset-4"
                               href="/pensionsberegner">Pensionsberegner
                            </a>*/}
                        <a key="Sundhed" className="mx-3 py-5 hover:gradient-border-bottom" href="/sundhed">Sundhed
                        </a>
                        {/*<a key="Kontakt" className="mx-3 py-5 hover:gradient-border-bottom" href="/kontakt">Kontakt
                        </a>}

                        {/*<a key="Login" className="mx-3 py-5 hover:gradient-border-bottom" href="/login">Login
                       </a>*/}
                    </div>

                    <button
                        className="text-white hidden lg:block mx-5 px-5 py-3 rounded-full text-neutral-white text-md bg-[#34842b] hover:button-brightness focus:outline-none focus:ring ring-black top-1">
                   <a href={"/kontakt"}>Kontakt os</a></button>

                    <button
                        onClick={() => setIsOpen(!isOpen)}
                        className="lg:hidden focus:outline-none mx-5"
                    >
                        <img
                            className={`${isOpen && 'hidden'}`}
                            src={hamburger}
                            alt="Menu"
                        />
                        <img
                            className={isOpen ? 'block' : 'hidden'}
                            src={close}
                            alt="Close"
                        />
                    </button>
                </nav>
            </div>
            <div
                className={`fixed inset-0 z-40 bg-gray-800 
      bg-opacity-50 ${isOpen ? 'block' : 'hidden'} inset-0`}
            >
                <div className="bg-white text-primary-dark-blue flex flex-col font-textbitter text-center mx-5 my-32 py-4 rounded">
                    <a key="Forside" className="py-2 hover:bg-green-100" href="/">Om ProPension
                    </a>
                    <a key="Pensionsberegner" className="py-2 hover:bg-green-100" href="/Firmapension">Firmapension
                    </a>
                    <a key="Om os" className="py-2 hover:bg-green-100" href="/Medarbejder">Medarbejder
                    </a>
                    <a key="Sundhed" className="py-2 hover:bg-green-100" href="/sundhed">Sundhed
                    </a>
                    <a key="Kontakt" className="py-2 hover:bg-green-100" href="/kontakt">Kontakt
                    </a>

                    {/*<a key="Login" className="py-2 hover:bg-green-100" href="/login">Login
                    </a>*/}
                </div>
            </div>
        </>
    );
}