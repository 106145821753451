import Navbar from "../components/layout/Navbar";
// @ts-ignore
import logowhite from "../components/icons/logowhitegreen.svg";
import {useInView} from "react-intersection-observer";

export default function Sundhed(){
    const {ref: navbarRef, inView: isVisibleNavbar} = useInView();
    const items = document.querySelectorAll('.hiddenTextBox')
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('show');
            }
        });
    });
    items.forEach((el) => observer.observe(el));
    return (
        <>
            <section id="Om" className="relative">
                <div className={`${isVisibleNavbar ? 'hidden' : 'visible'}`}>
                    <Navbar/>
                </div>

                <div className="block lg:hidden w-screen flex justify-start absolute">
                    <a href="/">
                        <img className="absolute scale-[120%] -top-8 text-white fill-current z-60" src={logowhite}
                             alt="ProPension logo"/>
                    </a>
                </div>

                <div className="w-screen absolute z-[100]">
                    <nav className="container mx-auto flex items-center justify-around relative flex-wrap ">
                        <div>
                            <a href="/">
                                <img className="hidden lg:block scale-[200%] left-16 top-0 text-white fill-current"
                                     src={logowhite} alt="ProPension logo"/>
                            </a>
                        </div>
                        <div className="hidden lg:block text-xl text-white">
                            <a key="Forside" className="mx-3 py-3 hover:underline underline-offset-4" href="/">Om ProPension
                            </a>
                            <a key="Firmapension" className="mx-3 py-5 hover:underline underline-offset-4"
                               href="/firmapension">Firmapension
                            </a>
                            <a key="Medarbejder" className="mx-3 py-5 hover:underline underline-offset-4"
                               href="/medarbejder">Medarbejder
                            </a>
                            {/*<a key="Pensionsberegner" className="mx-3 py-5 hover:underline underline-offset-4"
                               href="/pensionsberegner">Pensionsberegner
                            </a>*/}
                            <a key="Sundhed" className="mx-3 py-5 hover:underline underline-offset-4" href="/sundhed">Sundhed
                            </a>
                            <a key="Kontakt" className="mx-3 py-5 hover:underline underline-offset-4" href="/kontakt">Kontakt
                            </a>

                            {/*<a key="Login" className="mx-3 py-5 hover:underline underline-offset-4" href="/login">Login
                        </a>*/}
                        </div>

                    </nav>
                </div>


                <div ref={navbarRef} className="hero bg-image-sundhed3 min-h-[29em]">
                    <div className="hero-overlay bg-opacity-[70%] "/>
                    <div className="hero-content text-center text-neutral-content">
                        <div className="max-w-md">
                            <h1 className="mb-2 my-[1.8em] text-4xl font-bold lg:text-5xl">Sundhedsforsikring</h1>
                            <div className="divider-vertical"/>
                            <button className="btn hidden btn-outline bg-[#34842b] text-gray-300 hover:bg-green-800 border-[#34842b] hover:border-green-800"><a href="/kontakt">Kontakt os</a></button>
                        </div>
                    </div>
                </div>
                <section className="font-textbitter">
                    {/*<div className="flex flex-col w-full">
                    <div className="divider"/>
                    <div className="grid h-20 card bg-base-300 place-items-center text-3xl font-textbitter">Om ProPension</div>
                    <div className="divider"/>
                </div>*/}

                    <div className="hero my-10 ">
                        <div className="text-center hero-content flex-col lg:flex-row-reverse lg:text-left">
                            <img src={require("../images/sundhed4.jpeg")}
                                 className="max-w-[20em] rounded-lg shadow-xl lg:max-w-sm"/>
                            <div>
                                <h1 className="text-3xl font-bold text-[#34842b]">Sundhedspolitik</h1>
                                <p className="py-6 text-md ">Medarbejdere udgør en essentiel ressource for enhver virksomhed, og deres fysiske
                                    og mentale velbefindende på arbejdspladsen har stor betydning for virksomhedens
                                    succes.
                                    Mange virksomheder har etableret en sundhedspolitik, og undersøgelser viser, at det
                                    er økonomisk fordelagtigt for virksomhederne at arbejde systematisk med sundhed
                                    og velvære på arbejdspladsen.
                                    En velfungerende sundhedspolitik er ikke en garanti for at medarbejderne ikke bliver
                                    syge eller har brug for behandling. I den forbindelse spiller sundhedsordninger og -
                                    forsikringer en væsentlig rolle, som en del af sundhedspolitikken.</p>
                            </div>
                        </div>
                    </div>
                    <div className="hero my-10 ">
                        <div className="text-center hero-content flex-col lg:flex-row lg:text-left">
                            <img src={require("../images/sundhed8.jpeg")}
                                 className="max-w-[20em] rounded-lg shadow-xl lg:max-w-sm"/>
                            <div>
                                <h1 className="text-3xl font-bold text-[#34842b]">Sundhedsordning</h1>
                                <p className="py-6 text-md">Sundhedsordningen omfatter forebyggende behandlinger såsom <span className="italic">fysioterapi  psykologisk hjælp,
                                    kiropraktik, zoneterapi, massage samt telefoniske konsultationer med psykologer og
                                    lignende </span>. Denne ordning fungerer som en forsikring, der dækker omkostninger inden
                                    for sundhedspleje, før en skade eller sygdom opstår.</p>
                            </div>
                        </div>
                    </div>
                    <div className="hero my-10 ">
                        <div className="text-center hero-content flex-col lg:flex-row-reverse lg:text-left">
                            <img src={require("../images/sundhed6.jpg")}
                                 className="max-w-[20em] rounded-lg shadow-xl lg:max-w-sm"/>
                            <div>
                                <h1 className=" text-3xl font-bold text-[#34842b]">Sundhedsforsikring</h1>
                                <p className="py-6 text-md ">Sundhedsforsikringen træder i kraft efter indtruffet skade og dækker udgifterne i
                                    forbindelse med dine behandlinger. En sundhedsforsikring er det beskyttelse mod
                                    skader af psykisk eller fysisk karakter. Dækningen omfatter blandt andet
                                    <span className="italic"> speciallægeundersøgelser</span>, <span className="italic"> behandlinger</span>, <span className="italic"> genoptræning</span>, <span className="italic"> hjemmepleje</span> og <span className="italic"> efterkontrol </span>
                                    samt <span className="italic"> psykologisk hjælp</span>. Desuden dækkes også behandlinger hos <span className="italic"> fysioterapeuter</span>,
                                    <span className="italic"> zoneterapeuter</span> og lignende.</p>
                            </div>
                        </div>
                    </div>
                    <div className="hero my-10 ">
                        <div className="text-center hero-content flex-col lg:flex-row lg:text-left">
                            <img src={require("../images/AdobeStock_300312795.jpeg")}
                                 className="max-w-[20em] rounded-lg shadow-xl lg:max-w-sm"/>
                            <div>
                                <h1 className=" text-3xl font-bold text-[#34842b]">Uvildig rådgivning og formidling</h1>
                                <p className="py-6 text-md ">ProPension har som vores primære målsætning at imødekomme jeres behov og
                                    interesser. Som jeres uafhængige forsikringsformidler og rådgiver sikrer vi, at I får de
                                    bedste sundhedsordninger og -forsikringer på markedet med hensyn til priser,
                                    dækning og skattemæssige forhold.</p>
                            </div>
                        </div>
                    </div>
                </section>


            </section>
        </>

    );
}