import Navbar from "../components/layout/Navbar";


export default function privatlivspolitik(){

    return (
        <>
            <Navbar/>



            <div className="text-lg mx-10 my-20 grid grid-cols-1 content-evenlytext-left w-[80%]">

                <div className="m-5">
                    <h1 className="text-[#34842b] text-2xl font-bold left my-4">Formålet med behandling af persondata</h1>
                    ProPension yder forsikringsformidling for virksomhedskunder og private kunder, hvor vi
                    rådgiver om og servicerer de af kunden indgåede pensions- og forsikringsaftaler.
                    ProPension behandler personoplysninger til at kunne rådgive vores private kunder. Vi
                    behandler ligeledes personoplysninger vedrørende virksomhedens ansatte, når det er
                    nødvendigt for at rådgive om forsikringsprogrammet for virksomheden.<br/><br/>Vi behandler
                    desuden personoplysninger, når vi rådgiver virksomhedens ansatte.
                    Derudover behandles personoplysninger som en del af de dokumentationskrav, som
                    ProPension er underlagt.
                    For at kunne varetage vores bogføring og økonomiske rapporteringer, kan det også være
                    nødvendigt at behandle personoplysninger.
                </div>

                <div className="m-5">
                    <h1 className="text-[#34842b] text-2xl font-bold left my-4">Lovgivningen om behandling af personlige data</h1>
                    For at behandle almindelige personoplysninger skal der være et juridisk grundlag. Det er
                    et juridisk grundlag når:
                    <br/>- Behandlingen af personoplysning er nødvendig for at indgår i en kontrakt eller for at
                    opfylde en kontrakt, hvor du er eller bliver en part i.
                    <br/>- Behandlingen af personoplysning er nødvendig for at overholde en lovlig forpligtelse
                    såsom indsamling af skatteoplysninger til indberetning hos SKAT.
                    <br/>- Behandlingen er i vores legitime interesse, medmindre dine grundlæggende
                    rettigheder og frihedsrettigheder går forud for vores berettigede interesse. Det er
                    vores legitime interesse er at kontakte eksisterende og potentielle kunder med
                    henblik på markedsføring..
                </div>

                <div className="m-5">
                    <h1 className="text-[#34842b] text-2xl font-bold left my-4">Indsamling, registrering og opbevaring af personoplysninger</h1>
                    Vi indsamler kun de personoplysninger, der er nødvendige for os og vi behandler disse i
                    henhold til gældende databeskyttelseslovgivning. Afhængigt af formålet med
                    oplysningerne bestemmes, hvilke personlige oplysninger der er nødvendige.

                    <br/>
                    <p className="font-bold">Vi indsamler og registrerer personoplysninger inden for følgende kategorier, når det
                        er relevant: </p>
                    <br/>- Almindelige personoplysninger, herunder navn, adresse, e-mailadresse,
                    telefonnummer, stilling, titel, ansættelses- og fratrædelsesdato, fødselsdato, løn og
                    anden kompensation, pårørende, civilstatus, kundens forsikringsselskab og
                    dækninger.
                    <br/> - CPR-nummer
                    <br/> - Helbredsoplysninger- under Særlige kategorier af personoplysninger.
                    <br/>
                    <p className="font-bold">Vi indsamler personoplysninger fra følgende kilder: </p>
                    <br/>- Vores kunder
                    <br/>- Ansatte hos vores kunder

                    <br/>- Tredjeparter
                    <br/>- Offentlige myndigheder

                </div>

                <div className="m-5">
                    <h1 className="text-[#34842b] text-2xl font-bold left my-4">Opbevaring af personoplysninger:</h1>
                    Vi er forpligtet til at slette personoplysninger, når de ikke længere er relevante. Vi vil kun
                    opbevare personoplysninger i den tid, vi finder det sagligt relevant. Normalt vil vi ikke
                    opbevare personoplysninger i mere end 6 år efter afslutningen af vores rådgivningsforhold.<br/><br/>Vi kan træffe individuelle beslutninger om at opbevare personoplysninger i længere tid,
                    hvis vi vurderer det nødvendigt for at fastlægge, gøre gældende eller forsvare os mod
                    retskrav.
                </div>
                <div className="m-5">
                    <h1 className="text-[#34842b] text-2xl font-bold left my-4">Modtagere af personlige data</h1>
                    Alle medarbejdere hos ProPension har en forpligtelse til at bevare tavshedspligt. Vi
                    videregiver kun dine oplysninger til andre, når det er nødvendigt og i overensstemmelse
                    med gældende lovgivning. Der bliver videregivet personoplysninger til relevante
                    forsikringsselskaber, samarbejdspartnere og databehandlere med det formål at levere
                    forsikringsmæglerydelser til dig . Derudover kan vi videregive personlige oplysninger, når
                    vi er forpligtet til det i henhold til loven.
                </div>
                <div className="m-5">
                    <h1 className="text-[#34842b] text-2xl font-bold left my-4">Brug af databehandlere</h1>
                    Vi overlader dine personoplysninger til vores databehandlere, som bl.a. hoster, udvikler og
                    yder support til ProPensions IT-systemer. Vi har indgået aftaler med alle vores
                    databehandlere om at behandle personoplysninger fortroligt og tage passende tekniske og
                    organisatoriske foranstaltninger for at beskytte personoplysninger.
                </div>
                <div className="m-5">
                    <h1 className="text-[#34842b] text-2xl font-bold left my-4">Datasikkerhed</h1>
                    Vi tager beskyttelse af persondata alvorligt og har implementeret tekniske og
                    organisatoriske foranstaltninger for at sikre, at personoplysninger håndteres sikkert. Dette
                    inkluderer procedurer, politikker og IT-tekniske sikkerhedsforanstaltninger.
                </div>
                <div className="m-5">
                    <h1 className="text-[#34842b] text-2xl font-bold left my-4">Dine rettigheder</h1>
                    Hvis vi har adgang til dine personoplysninger, har du visse rettigheder.
                </div>
                <div className="m-5">
                    <h1 className="text-[#34842b] text-2xl font-bold left my-4">Ret til at se oplysninger</h1>
                    Du har ret til at få indsigt i de oplysninger, vi behandler om dig samt en række yderligere
                    oplysninger.
                </div>
                <div className="m-5">
                    <h1 className="text-[#34842b] text-2xl font-bold left my-4">Ret til berigtigelse</h1>
                    Hvis dine personoplysninger ikke er korrekte eller opdaterede, har du ret til at få dem
                    rettet.
                </div>
                <div className="m-5">
                    <h1 className="text-[#34842b] text-2xl font-bold left my-4">Ret til sletning</h1>
                    I særlige tilfælde har du ret til at få slettet oplysninger om dig selv, inden tidspunktet for
                    vores almindelige sletterutine indtræffer. Vi sletter dog kun dine oplysninger, hvis du ikke
                    længere er kunde hos os, og først når der ikke længere kan rettes krav mod os.
                </div>
                <div className="m-5">
                    <h1 className="text-[#34842b] text-2xl font-bold left my-4">Ret til at begrænse behandling af personoplysninger</h1>
                    Du har i nogle tilfælde ret til at få behandlingen af dine personoplysninger begrænset. Hvis
                    du har ret til at få begrænset behandlingen, må vi fremover kun behandle oplysningerne -
                    bortset fra opbevaring - med dit samtykke, eller med henblik på at retskrav kan
                    fastlægges, gøres gældende eller forsvares, eller for at beskytte en person eller vigtige
                    samfundsinteresser.
                </div>
                <div className="m-5">
                    <h1 className="text-[#34842b] text-2xl font-bold left my-4">Ret til indsigelse</h1>
                    Du har i nogle tilfælde ret til at gøre indsigelse mod vores ellers lovlige behandling af dine
                    personoplysninger.
                </div>
                <div className="m-5">
                    <h1 className="text-[#34842b] text-2xl font-bold left my-4">Ret til dataportabilitet</h1>
                    Du har i nogle tilfælde ret til at modtage dine personoplysninger i et struktureret,
                    almindeligt anvendt og maskinlæsbart format samt at få overført disse personoplysninger
                    fra én dataansvarlig til en anden uden hindring.
                </div>
                <div className="m-5">
                    <h1 className="text-[#34842b] text-2xl font-bold left my-4">Ret til dataportabilitet</h1>
                    Du har i nogle tilfælde ret til at modtage dine personoplysninger i et struktureret,
                    almindeligt anvendt og maskinlæsbart format samt at få overført disse personoplysninger
                    fra én dataansvarlig til en anden uden hindring.
                </div>
                <div className="m-5">
                    <h1 className="text-[#34842b] text-2xl font-bold left my-4">Tilbagekaldelse af samtykke</h1>
                    Hvis du har givet dit samtykke, har du altid mulighed for at trække det tilbage.
                </div>
                <div className="m-5">
                    <h1 className="text-[#34842b] text-2xl font-bold left my-4">Tilbagekaldelse af samtykke</h1>
                    Hvis du har givet dit samtykke, har du altid mulighed for at trække det tilbage.
                </div>
                <div className="m-5">
                    <h1 className="text-[#34842b] text-2xl font-bold left my-4">Kontakt os</h1>
                    Hvis du har nogen spørgsmål vedrørende vores behandling af dine personoplysninger,
                    ønsker at tilbagekalde dit samtykke eller anvende dine rettigheder, bedes du kontakte os
                    på info@pension.dk og på telefon 23905602<br/>
                    ProPension<br/>
                    Peter Dalsgaard<br/>
                    Sønder alle 30 A<br/>
                    8800 Viborg<br/>
                    CVR: 25293096<br/>
                </div>
                <div className="m-5">
                    <h1 className="text-[#34842b] text-2xl font-bold left my-4">Klagemulighed</h1>
                    I tilfælde af, at du ikke er tilfreds med, hvordan ProPension håndterer dine oplysninger, har
                    du mulighed for at klage til Datatilsynet:<br/>

                    Datatilsynet. Carl Jacobsens Vej 35, 2500 Valby.
                    Tel: 33193200
                    Email:dt@datatilsynet.dk.
                    Yderligere oplysninger om Datatilsynet kan findes på deres hjemmeside, som du kan
                    besøge via følgende link: www.datatilsynet.dk.
                </div>



            </div>

        </>
    );

}